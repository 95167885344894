import React, {
  useState,
  useMemo,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import { styled } from "@mui/material/styles";
import { useLoader, Canvas, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import {
  XR,
  XRButton,
  Controllers,
  Interactive,
  Hands,
  RayGrab,
  useXR,
  InteractiveProps,
  useController,
  useXREvent,
  XREvent,
  XRControllerEvent,
} from "@react-three/xr";
import {
  Color,
  Plane,
  Vector3,
  LinearFilter,
  CanvasTexture,
  Quaternion,
  Matrix4,
} from "three";
import { Link } from "react-router-dom";
import {
  OrbitControls,
  Environment,
  Sky,
  useVideoTexture,
  Html,
  Text,
  useTexture,
  StatsGl,
} from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import { PerfHeadless, usePerf } from "r3f-perf";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { ProjectedMaterial } from "@lume/three-projected-material";
// https://stackoverflow.com/questions/55371402/how-to-reference-pdf-js-library-in-react
// import pdfjsLib from "pdfjs-dist/build/pdf";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
// import dSplat from '../public/3d_gaussian_splatting_low.pdf';

// import 'react-pdf/dist/Page/AnnotationLayer.css';
// import 'react-pdf/dist/Page/TextLayer.css';

const showMeshTriangles = false;
const baseOriginGroup = new THREE.Group();
const allMeshOrigins = [];

function createGeometry(vertices: any, indices: any) {
  const geometry = new THREE.BufferGeometry();
  geometry.setIndex(new THREE.BufferAttribute(indices, 1));
  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.computeVertexNormals();

  return geometry;
}
let meshId = 1;
let allMeshes = new Map();
// Iterate over the meshes array and compare to our internal state
// This is the code that keeps track of the mesh state and adds them to the scene.
function processMeshes(
  timestamp: number,
  frame: any,
  renderer: any,
  scene: any,
  camera: any,
  texture: any
) {
  const referenceSpace = renderer.xr.getReferenceSpace();

  if (frame.detectedMeshes) {
    allMeshes.forEach((meshContext, mesh) => {
      // if a previous mesh is no longer reported
      if (!frame.detectedMeshes.has(mesh)) {
        // mesh was removed
        allMeshes.delete(mesh);
        console.debug("Mesh no longer tracked, id=" + meshContext.id);

        scene.remove(meshContext.mesh);
      }
    });

    // compare all incoming meshes with our internal state
    frame.detectedMeshes.forEach((mesh: any) => {
      const meshPose = frame.getPose(mesh.meshSpace, referenceSpace);
      let meshMesh;
      const leaveMeshesAlone = true;

      // this is a mesh we've seen before
      if (allMeshes.has(mesh)) {
        // may have been updated:
        const meshContext = allMeshes.get(mesh);
        meshMesh = meshContext.mesh;

        meshContext.meshMaterial.project(meshMesh);
        if (!leaveMeshesAlone) {
          if (meshContext.timestamp < mesh.lastChangedTime) {
            // the mesh was updated!
            meshContext.timestamp = mesh.lastChangedTime;

            const geometry = createGeometry(mesh.vertices, mesh.indices);
            meshContext.mesh.geometry.dispose();
            meshContext.mesh.geometry = geometry;
          }
          console.debug("Existing mesh detected, id=" + meshId);
        }
      } else {
        console.log({ mesh: mesh });
        // new mesh

        // Create geometry:
        const geometry = createGeometry(mesh.vertices, mesh.indices);

        // Didn't fix it
        // alphaTest: 0.0,
        // Does work if transparent is false and opacity is 1
        // So look into that more TODO
        const proMaterial = new ProjectedMaterial({
          camera, // the camera that acts as a projector
          texture, // the texture being projected
          //           alphaMap: texture,
          //color: "#000000",
          transparent: true,
          //alphaToCoverage: true,
          // toneMapped: false,
          opacity: 0.95,
          backgroundOpacity: 0,
          side: THREE.DoubleSide,
          frontFacesOnly: true,
        });
        proMaterial.uniforms.backgroundOpacity.value = 0;
        //  proMaterial.stencilWrite = true;
        proMaterial.depthWrite = true;
        proMaterial.depthFunc = THREE.LessDepth;
        // proMaterial.depthTest = false;
        console.log({ material: proMaterial });

        meshMesh = new THREE.Mesh(
          geometry,
          proMaterial
          // meshMaterials[meshId % meshMaterials.length]
        );
        proMaterial.project(meshMesh, false);
        meshMesh.matrixAutoUpdate = false;
        scene.add(meshMesh);
        // const originGroup = baseOriginGroup.clone();
        // originGroup.visible = false;

        // meshMesh.add(originGroup);
        // allMeshOrigins.push(originGroup);

        const meshContext = {
          id: meshId,
          timestamp: mesh.lastChangedTime,
          mesh: meshMesh,
          // origin: originGroup,
          meshMaterial: proMaterial,
        };

        allMeshes.set(mesh, meshContext);
        console.debug("New mesh detected, id=" + meshId);
        meshId++;
      }

      if (meshPose) {
        meshMesh.visible = true;
        meshMesh.matrix.fromArray(meshPose.transform.matrix);
      } else {
        console.debug("hide mesh");
        meshMesh.visible = false;
      }
    });
  }
  return allMeshes;
}

interface BadAppleVideoProps {
  scale: number;
  position: Vector3;
  videoUrl: any;
}

function BadAppleVideo(props: BadAppleVideoProps) {
  const play_gltf = useLoader(GLTFLoader, "/play_button.glb");
  const pause_gltf = useLoader(GLTFLoader, "/pause_button.glb");
  const [playing, setPlaying] = useState(false);
  const debugText = useRef<string>("");
  // const video_name = 'bad_apple.mp4';
  // const video_name = 'illuminati.mp4';
  const texture = useVideoTexture(props.videoUrl, {
    muted: false,
    start: playing,
  });
  const camera = useMemo(
    () => new THREE.PerspectiveCamera(45, 1, 0.01, 20),
    []
  );
  const meshMap = useRef<Map<any, any>>(null!);
  const controlRef = useRef<any>(null!);
  const moveCamera = useRef<string>("none");
  const leftController = useController("left");
  const rightController = useController("right");
  useEffect(() => {
    if (rightController?.controller !== undefined) {
      if (moveCamera.current === "rightController") {
        camera.position.copy(rightController.controller.position);
        // camera.position.copy(new Vector3(0, 0, 0));
        camera.quaternion.copy(rightController.controller.quaternion);
      }
      if (moveCamera.current === "head") {
        camera.position.copy(rightController.controller.position);
        // camera.position.copy(new Vector3(0, 0, 0));
        camera.quaternion.copy(rightController.controller.quaternion);
      }
    }
  }, [rightController, moveCamera]);

  const handleSelect = () => {
    texture.image.pause();
    setPlaying((state) => !state);
  };
  const { gl, scene } = useThree();
  const handleSelectStart = useCallback((event: XREvent<XRControllerEvent>) => {
    if (event.target.inputSource?.handedness === "right") {
      moveCamera.current = "rightController";
    }
    if (event.target.inputSource?.handedness === "left") {
      moveCamera.current = "head";
    }
  }, []);
  const handleSelectEnd = useCallback((event: XREvent<XRControllerEvent>) => {
    moveCamera.current = "none";
  }, []);

  useXREvent("selectstart", handleSelectStart);
  useXREvent("selectend", handleSelectEnd);
  // add a camer frustum helper just for demostration purposes
  // const helper = new THREE.CameraHelper(camera)
  //  scene.add(helper)
  useEffect(() => {
    if (leftController?.controller !== undefined) {
      console.log({ leftController });
      console.log({ controlRef: controlRef.current });
      leftController.controller.add(controlRef.current);
      // controlRef.current.position.copy(new Vector3(0, 0, 0));
      // controlRef.current.quaternion.copy(new Quaternion(0, 0, 0, 0));
      console.log({ controlRef: controlRef.current });
    }
  }, [leftController, controlRef]);
  useFrame((state, deleta, xrFrame) => {
    const refSpace = state.gl.xr.getReferenceSpace();
    if (refSpace !== null && xrFrame !== undefined) {
      const pose = xrFrame.getViewerPose(refSpace);
      const headPos = pose?.transform.position;
      const headPosition = new Vector3(headPos?.x, headPos?.y, headPos?.z);
      const orientation = pose?.transform.orientation;
      const headQuat = new Quaternion(
        orientation?.x,
        orientation?.y,
        orientation?.z,
        orientation?.w
      );
      if (moveCamera.current === "head") {
        camera.position.copy(headPosition);
        // camera.position.copy(new Vector3(0, 0, 0));
        camera.quaternion.copy(headQuat);
      }
    }
    if (rightController?.controller !== undefined) {
      if (moveCamera.current === "rightController") {
        camera.position.copy(rightController.controller.position);
        // camera.position.copy(new Vector3(0, 0, 0));
        camera.quaternion.copy(rightController.controller.quaternion);
      }
    }
    if (xrFrame !== undefined) {
      const meshes = processMeshes(0, xrFrame, gl, scene, camera, texture);
      meshMap.current = meshes;

      meshes.forEach((m) => {
        // m.meshMaterial.project(m.mesh)
        if (Math.random() > 0.98) {
          // console.log({m: m});
        }
      });
    }
  });

  // create the mesh with the projected material
  // const geometry = new THREE.BoxGeometry(1, 1, 1)
  const geometry = new THREE.BufferGeometry();
  const vertices = new Float32Array([
    -1.0,
    -1.0,
    -1.0, // v0
    1.0,
    -1.0,
    -1.0, // v1
    1.0,
    1.0,
    -1.0, // v2

    1.0,
    1.0,
    -1.0, // v3
    -1.0,
    1.0,
    -1.0, // v4
    -1.0,
    -1.0,
    -1.0, // v5
  ]);
  const normals = new Float32Array([
    -1.0,
    -1.0,
    -1.0, // v0
    1.0,
    -1.0,
    -1.0, // v1
    1.0,
    1.0,
    -1.0, // v2

    1.0,
    1.0,
    -1.0, // v3
    -1.0,
    1.0,
    -1.0, // v4
    -1.0,
    -1.0,
    -1.0, // v5
  ]);
  const uvs = new Float32Array([
    -1.0,
    -1.0, // v0
    1.0,
    -1.0, // v1
    1.0,
    1.0, // v2

    1.0,
    1.0, // v3
    -1.0,
    1.0, // v4
    -1.0,
    -1.0, // v5
  ]);
  // geometry.setIndex(new THREE.BufferAttribute(indices, 1));
  const positionNumComponents = 3;
  const normalNumComponents = 3;
  const uvNumComponents = 2;
  geometry.setAttribute(
    "position",
    new THREE.BufferAttribute(vertices, positionNumComponents)
  );
  geometry.setAttribute(
    "normal",
    new THREE.BufferAttribute(new Float32Array(normals), normalNumComponents)
  );
  geometry.setAttribute(
    "uv",
    new THREE.BufferAttribute(new Float32Array(uvs), uvNumComponents)
  );
  // const material = useMemo(
  //   () =>
  //     new ProjectedMaterial({
  //       camera,
  //       texture,
  //       color: "#37E140",
  //       side: THREE.DoubleSide,
  //       frontFacesOnly: false,
  //     }),
  //   [camera, texture]
  // );
  // const box = useMemo(() => new THREE.Mesh(geometry, material), [material]);
  // scene.add(box)
  // material.project(box)
  // box.position.set(0, 0, 0);

  // <RayGrab>
  // <mesh scale={props.scale} position={props.position} onClick={() => handleSelect()}>
  //   <planeGeometry />
  //         <meshBasicMaterial color={'white'} opacity={0.8} alphaMap={texture} toneMapped={false} transparent={true}/>
  // </mesh>
  // </RayGrab>
  //
  return (
    <>
      <group ref={controlRef}>
        <Text color={"white"} fontSize={0.2}>
          {debugText.current}
        </Text>
        <Interactive onSelect={() => handleSelect()}>
          <mesh onClick={() => handleSelect()}>
            <primitive
              object={play_gltf.scene}
              scale={0.05}
              position={new Vector3(-0.1, 0.2, 0.0)}
              rotation={[Math.PI / 3, 0, Math.PI / 2]}
              visible={!playing}
            />
          </mesh>
        </Interactive>
        <Interactive onSelect={() => handleSelect()}>
          <mesh onClick={() => handleSelect()}>
            <primitive
              object={pause_gltf.scene}
              scale={0.05}
              position={new Vector3(-0.1, 0.2, 0)}
              rotation={[Math.PI / 3, 0, Math.PI / 2]}
              visible={playing}
            />
          </mesh>
        </Interactive>
      </group>
    </>
  );
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// TODO: fix this as this approach doesn't handle the ar not supported text showing on the button.
const VisuallyHiddenXRButton = styled(XRButton)({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function VideoProjector() {
  const [videoUrl, setVideoUrl] = useState<any>(
    "Tom_and_Jerry_Jolly_Fish_1932_512kb.mp4"
  );

  // Handles new pdf being loaded
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files === null) {
      console.log("no files");
    } else {
      const file = event.currentTarget.files[0];
      const fileUrl = window.URL.createObjectURL(file);
      setVideoUrl(fileUrl);
    }
  };
  const [arSupported, setArSupported] = useState<boolean>(false);
  const [vrSupported, setVrSupported] = useState<boolean>(false);
  useEffect(() => {
    navigator.xr?.isSessionSupported("immersive-ar").then((supported) => {
      setArSupported(supported);
    });
    navigator.xr?.isSessionSupported("immersive-vr").then((supported) => {
      setVrSupported(supported);
    });
  }, []);

  const anyXrSupported = arSupported || vrSupported;
  const xrMode = arSupported ? "AR" : "VR";
  return (
    <>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography fontSize="1.5rem">
            <p>
              This website allows you to project MP4 videos onto your
              environment in mixed reality. Default video is{" "}
              <a href="https://archive.org/details/Jolly_Fish_1932">
                Tom and Jerry: Jolly Fish
              </a>{" "}
              from 1932.
            </p>
            <p>
              Left trigger/pinch: moves projector to head position. Right
              trigger/pinch: moves projector to right controller/hand position.
            </p>
          </Typography>
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid container item direction="column" spacing={2} xs={12}>
            <Grid item xs>
              <Button
                sx={{ marginTop: "24px" }}
                component="label"
                variant="contained"
              >
                Select Video
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleOnChange}
                  accept=".mp4"
                />
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                disabled={!anyXrSupported}
                component="label"
                variant="contained"
              >
                {arSupported
                  ? "Engage AR"
                  : vrSupported
                    ? "Engage VR"
                    : "XR Not Supported"}
                <VisuallyHiddenXRButton
                  mode={xrMode}
                  sessionInit={{
                    requiredFeatures: ["mesh-detection"],
                    optionalFeatures: [
                      "local",
                      "local-floor",
                      "bounded-floor",
                      "hand-tracking",
                      "layers",
                    ],
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Canvas
                id="rrt_star_canvas"
                style={{
                  minHeight: "300px",
                  height: "70vh",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                <ambientLight intensity={0.1} />
                <pointLight position={[10, 10, 10]} intensity={0.2} />
                <pointLight position={[0, 1, 0]} intensity={0.2} />
                <OrbitControls />
                <XR>
<Controllers />
<Hands />
                  <BadAppleVideo
                    videoUrl={videoUrl}
                    scale={1}
                    position={new Vector3(0, 1, -1)}
                  />
                </XR>
              </Canvas>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
